import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    XIcon,
  } from "react-share";
import axios from 'axios';
import API from './../../API';

const PropertyDetails = () => {
    const [property, setProperty] = useState({});
    const { propertyId } = useParams();
    useEffect(() => {
        axios.get(`${API}/properties/${propertyId}`).then(res => {
            setProperty(res?.data);
        }).catch( err => {
            setProperty({});
        });
    }, [propertyId]);
    return (
        <>
            { property ? 
            <>
                <div className='page-head'> 
                    <div className='container'>
                        <div className='row'>
                            { property?.installmentValue > 0 && <div className="col-xs-4 page-head-content">
                                <span style={{ textAlign: 'center'}}>
                                    <p style={{fontSize: '18px'}}> <i className="fa fa-money"></i> قيمة القسط </p>
                                    <p style={{fontSize: '25px'}}> {property?.installmentValue} ﷼ </p>
                                </span>
                            </div>}
                            <div className={property?.installmentValue > 0 ? 'col-xs-4 page-head-content' : 'col-xs-6 page-head-content'}>
                                <span style={{ textAlign: 'center'}}>
                                    <p style={{fontSize: '18px'}}> <i className="fa fa-check-square-o"></i> قيمة العقار </p>
                                    <p style={{fontSize: '25px'}}> {property?.cost} ﷼  </p>
                                </span>
                            </div>
                            <div className={property?.installmentValue > 0 ? 'col-xs-4 page-head-content' : 'col-xs-6 page-head-content'}>
                                <h1 className='page-title'>{property?.name}</h1>               
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <a className='btn button-xs'
                        href={'https://wa.me/' + property.admin?.phone || localStorage.getItem('phone')}
                        target="_blank"
                        style={{
                            position: 'fixed',
                            bottom: '10%',
                            left: '3%',
                            fontSize: '35px',
                            color:' #fff',
                            zIndex: '1600',
                            borderRadius: '12px',
                            backgroundColor: '#25d366',
                            paddingBottom: '1px',
                    }}> <i className="fa fa-whatsapp my-float"></i>
                    </a>
                </div>

                <div className='content-area single-property' style={{backgroundColor: '#FCFCFC' }}>
                    <div className='container'>   
                        <div className='clearfix padding-top-40' >
                            <div className='col-md-1'></div>
                            <div className='col-md-10'>
                                <div className='row'>    
                                    <div id='carousel-example-generic' className='carousel slide' data-ride='carousel'>
                                        <div className='carousel-inner' role='listbox'>
                                            { property?.images?.map((img, index) =>
                                                <div key={index} className={index === 0 ? 'item active' : 'item'} >
                                                    <img src={img?.src} alt='...' style={{width: '100%', maxHeight: '650px'}} />
                                                </div>
                                            )}
                                        </div>

                                        <a className='left carousel-control' href='#carousel-example-generic' role='button' data-slide='prev'>
                                            <span className='glyphicon glyphicon-chevron-left' aria-hidden='true'></span>
                                            <span className='sr-only'>السابق</span>
                                        </a>
                                        <a className='right carousel-control' href='#carousel-example-generic' role='button' data-slide='next'>
                                            <span className='glyphicon glyphicon-chevron-right' aria-hidden='true'></span>
                                            <span className='sr-only'>التالي</span>
                                        </a>
                                    </div>
                                </div>
                                <div className='single-property-wrapper'>
                                    <div className='section'>
                                        <h4 className='s-property-title'>التفاصيل</h4>
                                        <div className='s-property-content'>
                                            <p>{ property?.description }</p>
                                        </div>
                                    </div>

                                    <div className='section additional-details'>
                                        <h4 className='s-property-title'>مواصفات العقار</h4>
                                        <ul className='additional-details-list clearfix'>
                                            {property?.features?.map(feat => {
                                                return(
                                                    <li key={feat?.id}>
                                                        <span className='col-xs-6 col-sm-8 col-md-8 add-d-entry'>{feat?.description}</span>
                                                        <span className='col-xs-6 col-sm-4 col-md-4 add-d-title'>{feat?.feature?.name}</span>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>

                                    <div className='section'>
                                        <h4 className='s-property-title'> تواصل معنا </h4>
                                        <div className='s-property-content'>
                                            { localStorage.getItem('phone') ? 
                                                <p>
                                                    <span> <i className='pe-7s-call strong'> </i> {localStorage.getItem('phone')} </span>
                                                </p> : <></>
                                            }
                                            { localStorage.getItem('email') ? 
                                                <p style={{ textTransform: 'lowercase' }}>
                                                    <span> <i className='pe-7s-mail strong'> </i> {localStorage.getItem('email')} </span>
                                                </p> : <></>
                                            }
                                        </div>
                                    </div>

                                    { property?.video && <div className='section property-video'> 
                                        <h4 className='s-property-title'>فيديو العقار</h4> 
                                        <div className='video-thumb'>
                                            <a className='video-popup' href='yout' title='Virtual Tour'>
                                                <iframe width="75%" height="300"
                                                    src={ property?.video +'?controls=0&autoplay=1' }>
                                                </iframe>           
                                            </a>
                                        </div>
                                    </div> }

                                    <div className='section property-share'> 
                                        <h4 className='s-property-title'>شارك مع أصدقائك</h4> 
                                        <div className='roperty-social'>
                                            <FacebookShareButton
                                                url={window.location.href}
                                                style={{ margin: '5px' }}
                                                className="Demo__some-network__share-button"
                                            >
                                                <FacebookIcon size={32} round />
                                            </FacebookShareButton>

                                            <FacebookMessengerShareButton
                                                url={window.location.href}
                                                style={{ margin: '5px' }}
                                                appId="521270401588372"
                                                className="Demo__some-network__share-button"
                                            >
                                                <FacebookMessengerIcon size={32} round />
                                            </FacebookMessengerShareButton>

                                            <TwitterShareButton
                                                url={window.location.href}
                                                style={{ margin: '5px' }}
                                                className="Demo__some-network__share-button"
                                            >
                                                <XIcon size={32} round />
                                            </TwitterShareButton>

                                            <TelegramShareButton
                                                url={window.location.href}
                                                style={{ margin: '5px' }}
                                                className="Demo__some-network__share-button"
                                            >
                                                <TelegramIcon size={32} round />
                                            </TelegramShareButton>

                                            <WhatsappShareButton
                                                url={window.location.href}
                                                style={{ margin: '5px' }}
                                                separator=":: "
                                                className="Demo__some-network__share-button"
                                            >
                                                <WhatsappIcon size={32} round />
                                            </WhatsappShareButton>
                                            
                                            <LinkedinShareButton
                                                url={window.location.href}
                                                style={{ margin: '5px' }}
                                                className="Demo__some-network__share-button"
                                            >
                                                <LinkedinIcon size={32} round />
                                            </LinkedinShareButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-1'></div>
                        </div>
                    </div>
                </div>
            </> : <></>
            }
        </>
    );
};

export default PropertyDetails;